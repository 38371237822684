*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Tajawal', sans-serif;
}


#wrapper {
  position: relative;
  width: 100vw;
  overflow: scroll !important;
  padding: 10vh 0;
}

.branch {
  position: relative;
  margin-left: 300px;
}
.branch:before {
  content: "";
  width: 50px;
  border-top: 2px solid #0D6EFD;
  position: absolute;
  left: -100px;
  top: 50%;
  margin-top: 1px;
}

.entry {
  position: relative;
  min-height: 60px;
}
.entry:before {
  content: "";
  height: 100%;
  border-left: 2px solid #0D6EFD;
  position: absolute;
  left: -50px;
}
.entry:after {
  content: "";
  width: 50px;
  border-top: 2px solid #0D6EFD;
  position: absolute;
  left: -50px;
  top: 50%;
  margin-top: 1px;
}
.entry:first-child:before {
  width: 10px;
  height: 50%;
  top: 50%;
  margin-top: 2px;
  border-radius: 10px 0 0 0;
}
.entry:first-child:after {
  height: 10px;
  border-radius: 10px 0 0 0;
}
.entry:last-child:before {
  width: 10px;
  height: 50%;
  border-radius: 0 0 0 10px;
}
.entry:last-child:after {
  height: 10px;
  border-top: none;
  border-bottom: 2px solid #0D6EFD;
  border-radius: 0 0 0 10px;
  margin-top: -9px;
}
.entry.sole:before {
  display: none;
}
.entry.sole:after {
  width: 50px;
  height: 0;
  margin-top: 1px;
  border-radius: 0;
}

.label {
  display: block;
  width: 200px;
  padding: 5px 10px;
  line-height: 20px;
  text-align: center;
  border: 2px solid #0D6EFD;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -22.5px;
  line-break: anywhere;
}

/* .slideThree */
.slideThree {
  width: 80px;
  height: 26px;
  background: #333;
  margin: 20px 0;
  position: relative;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.slideThree:after {
  content: '3Gen';
  color: #0D6EFD;
  position: absolute;
  right: 10px;
  z-index: 0;
  font: 12px/26px Arial, sans-serif;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
}
.slideThree:before {
  content: '5Gen';
  color: #27ae60;
  position: absolute;
  left: 10px;
  z-index: 0;
  font: 12px/26px Arial, sans-serif;
  font-weight: bold;
}
.slideThree label {
  display: block;
  width: 36px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  background: #fcfff4;
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 50px;
  transition: all 0.4s ease;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}
.slideThree input[type=checkbox] {
  visibility: hidden;
}
.slideThree input[type=checkbox]:checked + label {
  left: 41px;
}

/* end .slideThree */
/*table pedigree */
.parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  }
  .div1,
  .div2,
  .div3,
  .div4,
  .div5,
  .div6,
  .div7,
  .div8,
  .div9,
  .div10,
  .div11,
  .div12,
  .div13,
  .div14,
  .div15{
    border:1px solid #004B99 ;
    display: grid;
    place-items: center;
  }
  .div1 { grid-area: 1 / 1 / 9 / 2; }
  .div2 { grid-area: 1 / 2 / 5 / 3; }
  .div3 { grid-area: 5 / 2 / 9 / 3; }
  .div4 { grid-area: 1 / 3 / 3 / 4; }
  .div5 { grid-area: 3 / 3 / 5 / 4; }
  .div6 { grid-area: 5 / 3 / 7 / 4; }
  .div7 { grid-area: 7 / 3 / 9 / 4; }
  .div8 { grid-area: 1 / 4 / 2 / 5; }
  .div9 { grid-area: 2 / 4 / 3 / 5; }
  .div10 { grid-area: 3 / 4 / 4 / 5; }
  .div11 { grid-area: 4 / 4 / 5 / 5; }
  .div12 { grid-area: 5 / 4 / 6 / 5; }
  .div13 { grid-area: 6 / 4 / 7 / 5; }
  .div14 { grid-area: 7 / 4 / 8 / 5; }
  .div15 { grid-area: 8 / 4 / 9 / 5; }
  /*------------------------------*/
  h3{
    background-color: #004B99;
    border-radius: 5px;
    width: 70vw !important;
    color: aliceblue;
    padding:0.5rem 2rem;
  }
  #wrapper{
    overflow-x: scroll;
  }

/* CSS */
.button-34 {
  background: #004B99;
  border-radius : 999px;
  box-shadow: #004B99 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Tajawal', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  word-break: break-word;
  border: 0;
  width:10rem !important;
  transition: all .5s ease;
}
.button-34:hover,
.button-34.active{
  background-color: lightcyan;
  color: #004B99;
  box-shadow: black 0 0 0 0;
}

.titre{
  background-image: linear-gradient(180deg ,rgba(0, 0, 0, 0.377), rgba(0, 0, 0, 0.603));
  width: 100%;
  height: 30%;
  transform: translate(-15% , 10%);
  opacity: 0;
  padding: .5rem;
  transition: all .5s ease;
}
.titre> p,
.titre>h4{
  white-space:'wrap'; 
  text-align:'center' ; 
  color:white !important ;
}
.carrousselImg:hover .titre{
  opacity: 1;
}
.newsImg{
  height: 379px;
  width: 100%;
}
.carrousselImg.carousel.slide{
  border-radius: 0px !important;
  box-shadow: white 10px 10px 40px !important;
  height: 379px !important;
  transform: translate(0, -19px);
  
}