@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@100;300;400;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Tajawal', sans-serif;
}
body {
  overflow-x: hidden;
}
:root {
  --mainColor: #1c1918;
  --mainColorLight: #5767aa;
  --secondaryColor: #0D6EFD;
  --textColor: #eee;
}
*::selection{
  background-color: #D94148 !important;
  color: var(--textColor) !important;
}
.nn{
  border-bottom: 3px solid #D94148;
}
.tree{color:#1c1918 !important;
}
::placeholder {
  color: black !important;
  opacity: 1 !important; /* Firefox */
}
.Header {
  background-color: var(--textColor);
  height: 93vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}
.carouselActuality{
  margin-top: 3rem;
  box-shadow: 10px 10px 20px rgb(117, 117, 117) ;
  max-width: 1366px;
  max-height: 600px;
  border-radius: 5px;
}
.Header .slide {
  position: absolute;
  height: 93vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  scale: (0);
  transition: all 0.5s ease;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  url(./images/P1040534-2-scaled.jpg);
  z-index: -1;
}
p{
  font-size: 1.5rem !important;
  line-height: 1.5 !important;
  font-weight: 100;
}
/*.cardDescription{
  font-size: 1.3rem !important;
}*/
.Header .slide.active {
  opacity: 1;
  transform: scale(1);
  z-index: 1;
}
.Header .slide-2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  url(./images/transfert-photos-canon-mai-2017-529-scaled.jpg);
}
.Header .slide-1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  url(./images/11908895_966278236744338_4209383271095800349_o.jpg);
}
.Header h1,
h2 {
  text-transform: uppercase;
}
.Header h1,
h2,
p {
  color: var(--secondaryColor);
  
}
.Header h1 {
  font-size: 4rem;
}
.Header p {
  font-size: 1.5rem;
  margin-top: 3vh;
}
.Header .arrow {
  position: absolute;
  z-index: 1000;
  color: var(--textColor);
  top: 80vh;
  background-color: rgba(128, 128, 128, 0.581);
  padding: 0.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
}
.Header .arrow:hover {
  background-color: rgba(128, 128, 128, 0.4);
}
.iconHolder{
  width:'100%';
  text-align:'center';
  color:#0d6efd;
}
p{
  color: black !important;
}
.iconHolder svg{
  font-size: 3rem !important;
}
.Header .arrow svg {
  font-size: 4rem;
}
.Header .arrow.back {
  left: 5vw;
}
.Header .arrow.forward {
  right: 5vw;
}
.Header .indicator {
  position: absolute;
  z-index: 10000;
  top: 20vh;
  right: 8vw;
  color: var(--textColor);
  font-size: 3rem;
  background-color: rgba(128, 128, 128, 0.581);
  padding: 0.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  height: 10vh;
  width: 10vh;
  cursor: crosshair;
}
.cont{
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0 !important;
}
.cont .row.title{
  width: 100%;
  height:100%;
  background-color:#0D6EFD;
  color: var(--textColor);
  padding: .5rem;
  border-top-left-radius:5px;
  border-top-right-radius:5px ;
}
.col{
  padding: 2px !important;
}
.col img{
  width: 100%;
  margin: 0;
  transition: all .5s ease;
}

.col.left img:hover {
  transform:scale(2) translateX(25%);
  box-shadow: 10px 10px 30px gray ;
}
.col.right img:hover {
  transform:scale(2) translateX(-25%);
  box-shadow: 10px 10px 30px gray ;
}
.col.left.bottom img:hover {
  transform:scale(2) translate(25%, -25%);
}
.col.right.bottom img:hover {
  transform:scale(2) translate(-25%, -25%);
}
.col.left.top img:hover {
  transform:scale(2) translate(25%, 25%);
}
.col.right.top img:hover {
  transform:scale(2) translate(-25%, 25%);
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 3vh;
}
.button {
  text-decoration: none;
  font-size: 1.5rem;
  color: var(--textColor);
  background-color: var(--mainColor);
  margin: 1rem;
  border: 1px solid var(--secondaryColor);
  border-radius: 5px;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
  z-index: 10000001;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.button.empty {
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1.3rem;
}
.button:hover {
  background-color: var(--mainColor);
  border: 1px solid var(--secondaryColor);
  text-decoration: underline;
}
.navMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap !important;
}
/*.dropdown {
  display: none;
  opacity: 0;
  visibility: hidden;
  z-index: 10000003;
  transition: all 0.5s ease-in;
}
.listDropUl {
  position: relative;
}
.dropdown.active {
  z-index: 1000000;
  visibility: visible;
  display: block;
  opacity: 1;
  position: absolute;
  background-color: var(--mainColor);
  padding: 3vh 20px;
  left: -50%;
  width: 20rem;
  border-bottom: 2px solid var(--secondaryColor);
  z-index: 100;
}

.dropdown.active li {
  margin-bottom: 1vh;
  border-bottom: 2px solid transparent;
  width: 12rem !important;
  text-align: center;
  transition: all 0.3 ease;
}
.dropdown.active li a {
  width: 12rem;
  font-size: 1rem !important;
}
.dropdown.active li:hover {
  border-bottom: 2px solid var(--secondaryColor);
}
*/
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7vh;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.9);
}
header li {
  list-style-type: none;
}
header::after {
  content: "";
  height: 2px;
  width: 100vw;
  position: absolute;
  top: 7vh;
  left: 0;
  background-color: var(--secondaryColor);
  z-index: 1000000000;
}

nav a,
li {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}
.a{
  color: var(--mainColor);
  transition: all 0.1s ease
}
nav a:hover,
li:hover ,
.a:hover{
  color: #D94148;
}
.fnarc{
  background-color:var(--textColor);
  height: 5rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem ;
}
.fnarcimg{
  transform: scale(1.5);
}
.searchInput input::placeholder{
  color:black !important ;
}
.eleveurs {
  cursor: pointer;
}
header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap !important;
}
/*Section*/
section {
  min-height: 75vh;
  width: 100vw !important;
  padding: 5vh;
  text-align: center;
  overflow:hidden;
}
section .sectionTitle {
  font-size: 4rem;
  font-weight: 100;
  color: var(--secondaryColor);
  text-align: center;
  letter-spacing: 2px;
  position: relative;
}
section .sectionTitle::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 20%;
  background-color: var(--secondaryColor);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
section .sectionDescription{
  width: 50vw;
  margin: 2vh auto;
  color: var(--mainColor)
}
/*Card*//*
#card {
  min-height: 75vh;
  width: 100%;
  background-image: url(./images/pattern\ \(1\).svg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2vw;
  clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 100%);
}
.card {
  height: 25rem;
  aspect-ratio: 1/1.2;
  background-color: var(--secondaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  flex-direction: column;
  border-radius: 10px;
  text-align: center;
  color: var(--textColor);
  cursor: pointer;
  box-shadow: inset 5px 0 1rem rgba(0, 0, 0, 0.188);
  padding: 2rem 1rem;
}
.card:hover .icon {
  background-color: rgba(198, 197, 197, 0.43);
  transition: all 0.5s ease;
}
.card:hover {
  background-color: #d0a258d0;
  transform: translateY(-1rem);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.24);
}
.card .icon {
  background-color: rgba(150, 150, 150, 0.43);
  height: 60px;
  width: 60px;
  display: grid;
  place-content: center;
  border-radius: 50%;
}
.card .icon svg {
  font-size: 2rem;
}

.description {
  color: var(--mainColor) !important;
  font-size: 1rem;
  text-align: center;
  margin-top: 1.5rem;
}
*/
.cheval{
  text-align: left !important;
  border-bottom: 1px solid var(--secondaryColor);
  font-size: 5px !important;
  margin: 1rem 0;
}

.banner {
  height: 50vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: example 6s ease infinite alternate ;

}
@keyframes example {
  from {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(https://images.unsplash.com/photo-1509889551393-72ebc60d3ad6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1174&q=80);
  }
  to {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(https://images.unsplash.com/photo-1563208723-b1d4ef49af86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80);
  }
}
/*gallery*/
.gallery img{
  height: 75vh;
  object-fit: cover;
}
.gallery{
  background-color:var(--textColor);
}
.carousel{
  margin-top: 3vh;
}
/* numbers*/

.numbers{
  min-height: 45vh;
  background-image: url(./images/pattern.png);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}
.stat{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  aspect-ratio: 1;
  height:32vh ;
  padding:3vh;
  background-color: var(--mainColor);
  border-radius:50% ;
  color: white;
  border: 2px solid white;
}
.stat .statIcon svg{
  font-size: 3rem;
}
.stat .statNumber{
  font-size: 2.5rem;
  letter-spacing: 2px;
  word-spacing: 5px;
  font-weight:100 ;
}
.stat .statName{
  font-size:2.3rem;
  line-height:1;
}
.gap0{
  gap: 0 !important;
}
/*contact*/
.form{
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1.5/1;
  background-image: url(./images/pattern.png);
  height:30rem;
  z-index: 0;
  border-radius: .7rem;
  
}
.location{
  width: 40vw;
  height:30rem;
  z-index: 3;
  border-radius: .7rem;
}

.container{
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.location{
  object-fit: cover;
}
.h6{
  color:var(--mainColor) !important;
  transition: all 0.1s ease-in;
}
.h6:hover{
  color:var(--secondaryColor) !important;
}
.staff{
  background-color: #eee ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.staff .member
{
    margin: 0;
    border-radius: 15px;
    width: 400px;
    padding: 40px 0;
    height: max-content;
    background-color: #fff;
    transition: all 1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.staff .member::after
{
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 0;
    height: 20px;
    transition: all 1s;
    background: var(--secondaryColor);
}

.staff .member::before
{
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    width: 0;
    height: 20px;
    transition: all 1s;
    background: var(--secondaryColor);
}

.staff img
{
    border-radius: 50%;
    transition: all 1s;
}

.staff .description h1
{
    color: var(--secondaryColor);
    text-align: center;
    transition: all .5s ease;
}

.staff .description h2
{
    font-family: 'Tajawal', sans-serif;
    text-align: center;
    color: var(--secondaryColor);
    font-weight: 600;
    font-size: 18px;
    transition: all .5s ease;
}
.staff .member:hover .description h1,
.staff .member:hover .description h2{
  transform: translateY(-1rem);
}

.staff .description p
{
    color: green;
    text-align: justify;
    padding: 0 15px;
}

.staff .social-media
{
    position: absolute;
    background-color: var(--secondaryColor);
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 10px 0;
    justify-content: space-around;
    transition: all 1s;
    width: 0;
    opacity: 0;
    border-radius: 0 0 15px 15px;
    color: #fff;
}

.staff .description .social-media svg
{
    font-size: 30px;
    transition: all 1s;
}

.staff .member:hover .social-media
{
    width: 100%;
    opacity: 1;
}

.staff .member:hover
{
    transform: scale(1.01) ;
    
}

.staff .member:hover img
{
    transform: scale(1.1);
    border: 2px solid var(--secondaryColor);
    transform: translateY(-1rem);
}

.staff .description .social-media svg:hover
{
    transform: scale(1.1);
    color: var(--mainColor);
}

.staff .member:hover::before
{
    width: 50%;
}

.staff .member:hover::after
{
    width: 50%;
}
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 50%;
}
input {
  font-family: 'Tajawal', sans-serif;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
input::placeholder , textarea::placeholder{
  color: var(--textColor);
}
input:placeholder-shown ~ label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}
label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}
input:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, var(--secondaryColor),  #a98244);
  border-image-slice: 1;
}
input:focus ~ label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #11998e;
  font-weight: 700;
}
/* reset input */
input:required, input:invalid {
  box-shadow: none;
}
textarea{
  background-color:transparent ;
  margin-top: 30px;
  color:var(--textColor);
  font-size:1rem;
  padding: .5rem ;
  border: 1px solid var(--secondaryColor);
}
textarea:focus, textarea:active , textarea:enabled{
  border: 2px solid var(--secondaryColor)
}
/* footer*/
.footer {
  min-height: 30vh;
  background-image: url(./images/pattern.png);
  display: flex;
  justify-content: space-around;
  align-items: center;  
  position: relative;
  flex-wrap: wrap;
  padding: 1rem;
}
.footer li {
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.footer ul{
  line-height: 2;
}
.footer h2{
  color:#D94148;
  margin-bottom: 1rem;
}
.contactFooter::after{
  content: " ";
  position: absolute;
  height: 30vh;
  width: 5px;
  background-color: #D94148;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.contactFooter, .servicesFooter{
  width: 40vw;
}
.toTop{
  position: fixed;
  right: 1.25rem;
  bottom: 1.25rem;
  cursor: pointer;
  aspect-ratio: 1;
  height: 4rem;
  border-radius:50%;
  background-color: #22222222 ;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondaryColor);
  border: 4px solid transparent;
  transition:all .5s ease;
  z-index: 300000;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
}
.toTop.active{
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.toTop svg{
  font-size:3rem ;

}
.toTop:hover{
  border: 4px solid var(--secondaryColor);
  background-color:var(--mainColor);
}
.missions{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


/*table*/
  table {
    border-collapse: collapse; 
    width: 100%;
    margin-bottom: 1rem;
    background: var(--textColor);
    border-radius: 10px;
    color: #212529; 
  }
    table th,
    table td {
      padding: 0.75rem;
      vertical-align: top;
      text-align: center;
      border-top: 1px solid #dee2e6; }
    table thead {
      background-color: var(--mainColor);
      color: var(--textColor);
    }
    table thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6; }
    table tbody + tbody {
      border-top: 2px solid #dee2e6; }

.listTableContainer{
  height:'39.5rem' !important;
  width: '100%' !important; 
  overflow-y:'scroll' !important;
  overflow-x:'hidden' !important;
}
.tableCell{
  text-align: center !important;
}
/*.navBar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}*/
.pagination{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.next , .prev{
  cursor: pointer;
}
/*navbar*/
nav{
  background-color: #5e90c5 !important;
}
.dropdown-toggle,
.nav-link{
  color: white !important;
}
a.NavLink.dropdown-item{
  color: black !important;
}
.section6{
  padding-top:90px;
  padding-bottom:50px;
}
.location{
  overflow: hidden;
}
.form-visiteur{
  padding-top:30%;
}
/*---------------------------------------------------------*/
/*------------------------*****Section6*****----------------*/
/*-----------------------------------------------------------*/
.section6{
  padding-top:90px;
  padding-bottom:50px;
  background-color: rgba(212, 212, 212, 0.822);
}
.location{
  overflow: hidden;
}
.form-visiteur{
  padding-top:30%;
}
/*---------------------------------------------------------*/
/*------------------------*****Section6*****----------------*/
/*-----------------------------------------------------------*/
@media only screen and (max-width:1366px){
  .dropdown-toggle,
.nav-link{
  color: black !important;
}
}
@media only screen and (max-width: 1024px) {
  :root{
    font-size: 12px;
  }
  p{
    font-size: 1.5rem !important;
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  .navMenu {
    display: flex;
    flex-direction: column;
  }
  header::after {
    display: none;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100vh);
    z-index: 1000000000000000;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .card{
    width: 90vw;
  }
  #card{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    gap: 2rem;
  }
  .button{
    font-size: 1rem !important;
  }
  .Header .arrow {
    position: absolute;
    z-index: 1000;
    color: var(--textColor);
    background-color: rgba(128, 128, 128, 0.581);
    padding: 0.5rem;
    width:40px;
    height:40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
    top: 92vh;
    
  }
  p{
    font-size: .8rem !important;
  }
  .Header .arrow:hover {
    background-color: rgba(128, 128, 128, 0.4);
  }
  
  .Header .arrow svg {
    font-size: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50% , -50%)
  }
  
  .banner{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .Header .indicator{
    display: none ;
  }
  .numbers{
    background-image: url(./images/pattern.png);
  }
  .stat{
    height: 20vh;
  }
  .statName  , .statNumber{
    letter-spacing: 0;
    word-spacing: 2px !important;
    font-size: 1rem !important;
  }
  .statIcon svg {
    font-size: 1.5rem;
  }
  .flex{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contactFooter::after{
    display: none;
  }
  .location , .form{
    width: 100vw;
    align-self: center;
    border-bottom: 2px solid var(--secondaryColor);
  }
  .contactFooter, .servicesFooter{
    width: 100vw !important;
  }
  .staff{
    
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%) !important;
  }
  section.contact{
    padding: 0;
    margin: 0;

  }
  /*.dropdown.active {
    z-index: 1000000;
    display: block;
    opacity: 1;
    position: absolute;
    left: 50%;
    top: -50%;
    transform: translateX(-50%);
    background-color: var(--mainColor);
    padding: 3vh 20px;
    width: 20rem;
    height: 50vh;
    border-bottom: 2px solid var(--secondaryColor);
    z-index: 100;
  }
  .dropdown.active::before{
    content: "";
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--secondaryColor);
    position:absolute;
    top: 0;
    left: 30%;
    transform: translateX(-50%);
    z-index:10000033 ;
  }
  .dropdown.active li {
    margin-bottom: 1vh;
    border-bottom: 2px solid transparent;
    width: 100%;
    text-align: left;
    transition: all 0.3 ease;
  }*/
  .tableContainer{
    overflow-x: scroll;
  }
}
.video{
  position: absolute;
  top: auto !important;
}
.video > video{
  width: 100vw !important;
}
nav > a{
  color: #1c1918 !important;
  border-bottom: 2px solid transparent;
  transition: all .3s ease;
}
nav > a:hover{
  border-bottom: 2px solid #D94148;
}
#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  padding: 0;
  margin: 0;
}