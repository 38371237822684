.btn-flip-container {
  position: relative;
  height: 34px;
  margin-bottom: 10px;
  display: inline-block;
}
.btn-flip-container:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.btn-flip {
  position: relative;
  bottom: 0;
  border: 2px solid #98c1e9;
  padding: 5px 10px;
  margin-bottom: 0;
  color: #337ab7;
  background: transparent;
  z-index: 9;
  overflow: hidden;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-flip {
  position: absolute;
}
.btn-flip:hover,
.btn-flip:focus {
  text-decoration: none;
}
.btn-flip:before,
.btn-flip:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 0;
  width: 100%;
  background: rgba(51, 122, 183, 0.2);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-flip:after {
  top: auto;
  bottom: 0;
  right: -100%;
  left: auto;
}
.btn-flip:hover:before {
  height: 100%;
  left: 0;
}
.btn-flip:hover:after {
  height: 100%;
  right: 0;
}
.liasse-titre,
.liasse-corps {
  background: #004B99;
  color: #fff;
  padding: 7px 10px;
}
.liasse-corps {
  background: #eeee;
  color: #004B99;
  padding: 7px 10px 3px;
}
p.liasse-btn {
  margin-bottom: 4px;
}
p.liasse-btn > a {
  background: #fff;
  color: #222;
  border: #91bfd3 solid 1px;
  transition: all 0.3s ease;
  padding: 6px;
  width: 100%;
  display: block;
  text-align: center;
}
p.liasse-btn > a:hover {
  background: #10772C;
  color: #fff;
  border: #c7ddb9 solid 1px;
  text-decoration: none;
  padding: 6px;
}
p.liasse-btn > a > i {
  font-size: 150%;
}
.width-auto {
  width: auto;
}
.gauche {
  float: left;
  margin-right: 8px;
}
.droite {
  float: right;
  margin-left: 8px;
}
@media (max-width: 600px) {
  .gauche,
  .droite {
    float: none;
    display: block;
    margin: 0 auto;
  }
}
.panel-heading,
.panel-heading h4 {
  padding: 0;
  margin: 0;
  width: 100%;
}
.panel-heading h4 a {
  padding: 15px;
  width: 100%;
  display: inline-block;
  text-decoration: none;
  background: #fafafa;
  color: #004B99;
  font-size: 16px;
}
.panel-heading h4 a:hover,
.panel-heading h4 a:focus {
  background: #efeeee;
}
header {
  background: #fff;
  min-height: 20vh;
  width: 100vw;
}
@media (max-width: 540px) {
  .logo .slogan {
    display: none;
  }
}
.logo .vert {
  color: #10772C;
}
.slider-container {
  overflow: hidden;
  margin-bottom: 15px;
}
.bg-blanc {
  background: #fff;
  color: #fff;
}
.bg-blue {
  background: #004B99;
  color: #fff;
}
.bg-orange {
  background: #10772C;
  color: #fff;
}
a.link-brd {
  display: table;
  width: 100%;
  text-decoration: none;
  color: #222;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  border: #fff solid 1px;
}
a.link-brd .title {
  color: #222;
}
a.link-brd:hover {
  border: #ced8dd solid 1px;
}
a.link-brd:hover .title,
a.link-brd.active .title {
  color: #004B99;
}
a.link-brd:hover .image img {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
}
a.link-brd.bg-blue,
a.link-brd.bg-orange,
a.link-brd .bg-blue,
a.link-brd .bg-orange,
a.link-brd.bg-blue:hover,
a.link-brd.bg-orange:hover,
a.link-brd:hover .bg-blue,
a.link-brd:hover .bg-orange,
a.link-brd.bg-blue:focus,
a.link-brd.bg-orange:focus,
a.link-brd:focus .bg-blue,
a.link-brd:focus .bg-orange {
  color: #fff;
}
a.link-brd.bg-blue:hover,
a.bg-blue.link-brd:focus {
  background: #1673d0;
}
.texte {
  padding: 7px;
  font-size: 1.5rem !important;
}
.espace-telechargement .image {
  padding: 15px;
}
.espace-telechargement .text {
  text-align: center;
  padding: 10px 0;
  margin: 0;
}
.t-white {
  background: #fff;
  color: #222;
}
.t-orange {
  background: #10772C;
  color: #fff;
}
.t-white-b {
  color: #fff;
}
.rounded {
  border-radius: 5px;
}
.bg-black {
  background: rgba(0, 0, 0, 0.5);
}
.t-bold-title {
  background-color: #7cc152;
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  line-height: 1;
  padding: 10px;
  border-radius: 5px;
}
.t-bold-text,
.t-bold-plus {
  font-size: 24px;
  line-height: 1;
  color: #353535;
  font-weight: 700;
}
.t-bold-plus {
  color: #7cc152;
}
.nopadding {
  padding: 0 !important;
}
.padding-all {
  padding: 15px !important;
}
.padding-vertical {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.margin-left {
  margin-left: 15px !important;
}
.margin-right {
  margin-right: 15px !important;
}
.margin-bottom {
  margin-bottom: 15px !important;
}
.margin-top {
  margin-top: 15px !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.image {
  overflow: hidden;
  margin: 0;
  cursor: pointer;
}
.image img {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.image:hover img {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}
.petit {
  font-size: 90%;
}
.grand {
  font-size: 120%;
}
iframe {
  overflow: hidden;
  max-width: 100%;
}
.formulaires iframe {
  border: 0;
  min-height: 1060px;
}
.required {
  color: #f00;
  font-weight: bold;
}
.btn-rounded {
  border-radius: 100%;
}
svg {
  max-width: 100%;
}
.tippy-box {
  background: rgba(0, 88, 124, 0.9);
  border-radius: 0;
}
.logo {
  margin: 10px 0 15px;
  color: #00557e;
}
.logo img {
  float: left;
  margin-right: 30px;
}
.logo .slogan {
  font-size: 18px;
  margin-top: 50px;
}
.recherche {
  position: absolute;
  top: 43px;
  right: 100px;
  background: none;
  z-index: 90;
}
.navbar-form {
  padding: 0;
}
.recherche .btn_recherche {
  background: none;
  top: 10px;
  right: 5px;
  position: absolute;
  border: 0;
}
.navbar-form .form-group {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  float: right;
}
.recherche .form-control {
  width: 180px;
  height: 30px;
}
.rep {
  width: 214px;
  font-size: 13px;
  margin: 5px 6px 5px 0;
  line-height: 16px;
  color: #164c34;
}
.rep img {
  float: right;
  margin-left: 2px;
}
.rep span {
  font-size: 14px;
}
.rep hr {
  margin: 3px 0;
}
.fb {
  color: #3a589b;
  position: absolute;
  top: 6px;
  right: 7px;
  z-index: 999999;
}
.fb span {
  position: relative;
  top: -8px;
  margin-right: 5px;
}
.fb a {
  margin-left: 5px;
}
.fb .fa {
  height: 32px;
  width: 32px;
  line-height: 32px;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  background: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.fb .fa-facebook {
  background: #3a589b;
}
.fb .fa-twitter {
  background: #28a9e0;
}
.fb .fa-youtube {
  background: #cc181e;
}
.fb .fa:hover {
  background: #10772C;
}
.langue {
  position: absolute;
  right: 1px;
  top: 50px;
  z-index: 9998;
}
.langue a {
  font-size: 14px;
  color: #fff;
  margin-right: 5px;
  text-decoration: none;
  position: relative;
  padding: 3px 5px 1px;
  background: #91bfd3;
  display: inline-block;
  height: 32px;
  width: 70px;
  text-align: center;
  line-height: 24px;
  overflow: hidden;
  z-index: 1;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}
.langue a.ar {
  padding: 0px;
  font-size: 16px;
}
.langue a:hover,
.langue a:focus {
  background: #fff;
  color: #004B99;
}
.master-container {
  padding: 0;
}
.master-container .content {
  background: #fff;
  padding: 15px;
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}
.master-container .content ul {
  overflow: hidden;
}
.home .master-container .container {
  background: none;
}
.head_title {
  font-size: 115%;
  color: #fff;
  position: relative;
  padding: 10px 15px;
  z-index: 3;
  background: #004B99;
  border-bottom: 4px solid #91bfd3;
  margin: 0;
}
.bg-gris-clair {
  background: #ecf0f2;
}
.title_2 {
  font-size: 13px;
  padding: 6px;
}
.head_title.orange {
  background: #10772C;
  border-bottom: 4px solid #1baa41;
}
.head_title.orange a {
  color: #fff;
}
.size-normal {
  font-size: 100%;
  text-align: center;
}
.slider .slides > li {
  position: relative;
}
#acces-rapide {
  position: relative;
  overflow: hidden;
}
#acces-rapide .slides li img {
  width: 100% !important;
}
.ad-text {
  border: 1px solid #ecf0f2;
  background: rgba(255, 255, 255, 0.7);
  z-index: 999;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #222 !important;
  width: 100% !important;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
#acces-rapide {
  margin: 0;
}
#acces-rapide:hover .ad-text,
#acces-rapide:focus .ad-text {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.8);
}
.col-sid,
.col-content {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0;
  background: #fff;
}
.title {
  font-weight: 400;
  position: relative;
  padding: 8px 0;
  width: 100%;
  text-align: center;
  display: inline-block;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}
.col-content .title {
  font-size: 16px;
  background: #004B99;
  color: #fff;
}
.col-content .title:hover {
  text-decoration: none;
}
.col-content .orange .title {
  background: #10772C;
}
.col-content a.title:before,
.col-content a.title:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 0;
  width: 100%;
  background: rgba(196, 219, 242, 0.2);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.col-content .orange a.title:before,
.col-content .orange a.title:after {
  background: rgba(255, 208, 148, 0.2);
}
.col-content a.title:after {
  top: auto;
  bottom: 0;
  right: -100%;
  left: auto;
}
.col-content a.title:hover:before {
  height: 100%;
  left: 0;
}
.col-content a.title:hover:after {
  height: 100%;
  right: 0;
}
.col-content.siat .text-siat {
  padding: 10px;
}
.col-content.siat .text-siat h4 {
  padding: 7px 0;
  margin: 0;
}
.col-content .title-agenda {
  margin: 0;
}
.content .video {
  position: relative;
}
.content .video .title {
  height: 70px;
  width: 100%;
  padding: 10px;
  display: table-cell;
  vertical-align: middle;
}
.content .video .image {
  display: table-row;
}
.col-content.video .text {
  margin: 8px;
}
.col-pepiniere {
  position: relative;
  width: 100%;
  display: table;
  border: 1px solid #ddd;
  height: 150px;
  margin-bottom: 10px;
}
.col-pepiniere .titre,
.col-pepiniere .img {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
}
.col-pepiniere .titre {
  padding: 5px;
  text-align: center;
}
.col-pepiniere .titre a {
  font-size: 14px;
}
.col-pepiniere .titre img {
  float: left;
  margin: 0 0 0 10px;
}
.sidebar .col-sid {
  padding: 5px 10px;
}
.sidebar .col-sid.col-sid-2 {
  padding: 5px 25px 0;
}
.sidebar .col-sid .title {
  text-align: left;
  position: relative;
  margin: 4px;
  padding: 0;
  font-size: 1.5rem;
}
.file {
  font-size: 16px;
}
.file .fa {
  font-size: 18px;
  padding: 10px;
  color: #d47c00;
  margin-right: 7px;
  border: 1px solid #ddd;
  border-radius: 100%;
}
.col-sid .text {
  padding: 0 0 0 10px;
  margin-bottom: 10px;
  margin-left: 44px;
  font-size: 13px;
  border-left: 1px solid #ddd;
}
.col-sid .text p {
  margin: 0;
}
.flexslider,
.slider {
  overflow: hidden;
  margin: 0;
}
.flexslider .ad-text {
  font-size: 36px;
  color: white;
  line-height: 40px;
}
.flexslider .ad-text-sm {
  color: #e1e1e1;
  font-size: 20px;
  font-weight: 700;
}
.flexslider .ad-btn {
  padding: 15px 25px;
  font-size: 30px;
  line-height: 30px;
  background: #427aed;
  color: #f1f1f1;
  text-decoration: none;
  border-radius: 3px;
  border-bottom: 4px solid #2d6ae2;
}
.flexslider .ad-btn:hover,
.flexslider .ad-btn:focus {
  color: #fff;
  text-decoration: none;
}
#actualites {
  background: #fff;
  padding: 20px;
  height: 336px !important;
  position: relative;
  overflow: visible;
}
#actualites h4 {
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 16px;
}
#actualites p,
#actualites ul {
  font-size: 14px !important;
  margin-bottom: 15px;
}
#actualites .lush-nav {
  left: auto !important;
  line-height: 20px !important;
  height: 38px;
  top: -37px;
  position: absolute;
  right: 0;
}
#actualites.lush-slider .lush-prev,
#actualites.lush-slider .lush-next {
  display: none;
}
#actualites.lush-slider .lush-page {
  position: relative;
  right: 2px;
  top: 4px;
  z-index: 9;
  text-indent: inherit !important;
  color: #222;
  background: #91bfd3;
  padding: 0px 9px;
  margin-right: 3px;
  border-radius: 15px;
  text-decoration: none;
}
#actualites.lush-slider .lush-page.current,
#actualites.lush-slider .lush-page:hover {
  background: #fff;
}
#actualites .txt img {
  width: auto !important;
}
input[type="submit"],
button:not(.btn_recherche),
.toutes_actualites,
.button {
  position: relative;
  bottom: 0;
  border: 2px solid #98c1e9;
  padding: 5px 10px;
  margin-bottom: 0;
  color: #337ab7;
  background: transparent;
  z-index: 9;
  overflow: hidden;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  min-width: fit-content !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.toutes_actualites {
  margin-bottom: 10px;
  position: absolute;
  bottom: 15px;
}
.toutes_actualites:hover,
input[type="submit"]:hover,
button:not(.btn_recherche):hover,
.button:hover,
.toutes_actualites:focus,
input[type="submit"]:focus,
button:not(.btn_recherche):focus,
.button:focus {
  text-decoration: none;
}
.toutes_actualites:before,
input[type="submit"]:before,
button:not(.btn_recherche):before,
.button:hover:before,
.toutes_actualites:after,
input[type="submit"]:after,
button:not(.btn_recherche):after,
.button:focus:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 0;
  width: 100%;
  background: rgba(51, 122, 183, 0.2);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.toutes_actualites:after,
input[type="submit"]:after,
button:not(.btn_recherche):after,
.button:after {
  top: auto;
  bottom: 0;
  right: -100%;
  left: auto;
}
.toutes_actualites:hover:before,
input[type="submit"]:hover:before,
button:not(.btn_recherche):hover:before,
.button:hover:before {
  height: 100%;
  left: 0;
}
.toutes_actualites:hover:after,
input[type="submit"]:hover:after,
button:not(.btn_recherche):hover:after,
.button:hover:after {
  height: 100%;
  right: 0;
}
.news .news-img {
  text-align: center;
}
.news .news-info {
  padding: 10px 0;
  font-size: 16px;
  line-height: 24px;
}
.news .news-info ul {
  padding: 0 0 2px 25px;
  margin: 0;
}
.news .titre {
  padding: 0 0 15px;
  font-size: 16px;
}
.news .num,
.news .date,
.news .pdf {
  padding: 0 0 10px;
}
.mediatheque p {
  padding: 7px;
  font-size: 16px;
}
.title-absolute {
  position: absolute;
  left: 0;
  top: 10%;
  background: #fff;
  padding: 7px 10px;
  font-size: 120%;
  z-index: 99;
}
.pub {
  height: 192px;
  background: #fff;
}
.pub img {
  width: 100%;
  height: 100%;
}
.flexslider .text {
  line-height: 20px !important;
  font-size: 100% !important;
  display: inline-block !important;
  width: auto !important;
  height: auto !important;
  max-width: 100%;
  padding: 5px !important;
}
.text-ar {
  font-size: 120% !important;
}
.flexslider .big {
  font-size: 18px !important;
  line-height: 28px !important;
  width: 100% !important;
  padding: 3px 5px 7px !important;
}
.pub h2 {
  font-size: 140% !important;
  padding: 7px !important;
}
.pub h2 a {
  color: #fff;
  text-decoration: none;
}
.pub h2 a:hover,
.pub h2 a:focus {
  color: #e4e4e4;
}
.pub h2 span.ar {
  font-size: 140%;
  top: 3px;
  position: relative;
  margin-left: 3px;
}
.pub h3 {
  font-size: 120% !important;
}
.pub h3.text-ar {
  font-size: 140% !important;
}
.t-bleu {
  background: #004B99;
  color: #fff;
}
.date-act {
  margin: 10px 0 !important;
}
.breadcrumb {
  display: inline-block;
  width: 100%;
  font-size: 11px;
  padding: 0;
  background: #fff;
  margin-bottom: 5px;
  color: #999;
}
.breadcrumb a {
  color: #999;
}
.partenaires {
  background: #fff;
  padding: 0;
}
.partenaires .main {
  padding: 15px;
}
footer {
  padding: 15px 0;
  background: #313131;
  color: #9c9c9c !important;
  font-size: 1rem;
}
footer a,
footer a:hover,
footer a:focus {
  color: #9c9c9c;
}
footer .telechargement {
  display: inline-block;
  width: 100%;
  padding: 0;
}
footer .telechargement h4 {
  font-size: 110%;
  padding: 0;
}
footer .telechargement h4 a {
  width: 100%;
  display: inline-block;
  text-decoration: none;
  padding-bottom: 7px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
footer .telechargement h4 a:hover,
footer .telechargement h4 a:focus {
  color: #10772C;
}
footer .telechargement div {
  padding: 0 7px;
  max-width: 120px;
  font-size: 90%;
  line-height: 14px;
}
footer .telechargement div span {
  display: inline-block;
  width: 100px;
}
footer .telechargement div img {
  margin-bottom: 5px;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
footer .telechargement div img:hover,
footer .telechargement div img:focus {
  border: 2px solid #ddd;
}
.contact {
  margin: 0;
  padding: 20px 0 0 20px;
  font-size: 110%;
  line-height: 18px;
}
.contact h4 {
  color: #9c9c9c;
}
.contact ul,
.contact li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.contact ul {
  padding: 0;
}
.contact .reseaux-sociaux {
  font-size: 18px;
  padding: 15px 0;
}
.contact .reseaux-sociaux i.fa {
  height: 32px;
  width: 32px;
  line-height: 34px;
  border-radius: 100%;
  color: #313131;
  background: #ddd;
  text-align: center;
  margin-left: 5px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.contact .reseaux-sociaux i.fa:hover {
  color: #fff;
}
.contact .reseaux-sociaux i.fa.fa-facebook:hover {
  background: #3a589b;
}
.contact .reseaux-sociaux i.fa.fa-twitter:hover {
  background: #28a9e0;
}
.contact .reseaux-sociaux i.fa.fa-youtube:hover {
  background: #cc181e;
}
.newsletter .navbar-form {
  padding: 0;
  margin: 0;
}
.newsletter .navbar-form .form-control {
  border-radius: 0;
  height: 26px;
  padding: 6px;
  background-color: #f1f1f1;
}
.btn {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}
.newsletter .btn {
  color: #444343;
  border-radius: 0;
  border: 3px solid transparent;
  padding: 0 7px;
  margin: 0;
  background: #ddd;
}
.newsletter .btn:hover,
.newsletter .btn:focus {
  background: #004B99;
  color: #fff;
}
.footer-bottom {
  padding: 15px 0;
  color: #4c4c4c;
  background: #222;
}
.footer-bottom a,
.footer-bottom a:hover,
.footer-bottom a:focus {
  color: #4c4c4c;
}
.bloc {
  border-left: 1px solid #ddd;
  margin: 0 0 15px;
  padding: 7px 7px 0;
  display: inline-block;
  width: 100%;
}
.paginate_button .fa {
  line-height: 1.42857143;
}
#calendar_example {
  background: #004B99;
  padding: 4px;
  color: #fff;
}
#calendar_example table.date {
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
}
#calendar_example table.date caption {
  color: #fff;
  text-align: center;
  padding: 7px 0;
  position: relative;
}
.prev_date,
.next_date {
  color: #fff;
  position: absolute;
  left: 5px;
  cursor: pointer;
  top: 50%;
  margin-top: -8px;
}
.next_date {
  right: 5px;
  left: auto;
}
.hrefHidden {
  display: none;
}
#calendar_example table.date td,
#calendar_example table.date th {
  padding: 1px;
  text-align: center;
}
.no_event.detail {
  background: #91bfd3;
  padding: 3px;
}
.event_content,
.event_content:hover,
.event_content:focus {
  color: #fff;
}
.plan_site {
  border-left: 1px solid #ddd;
  padding: 10px;
  margin-left: -15px;
}
.plan_site h3,
.plan_site h3 a {
  font-size: 17px;
  color: #004B99;
  margin-bottom: 10px;
}
.plan_site ul,
.plan_site li,
.plan_site h4 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.plan_site h4,
.plan_site li {
  margin-bottom: 5px;
}
.plan_site li li {
  margin-left: 10px;
}
.supports .col-content {
  border-left: 1px solid #ddd;
  margin-left: -15px;
  margin-bottom: 15px;
  min-height: 210px;
  padding: 5px 0 0;
}
.supports .titre {
  margin-top: 10px;
}
.photo_media {
  width: 100%;
  position: relative;
}
.photo_media .msg {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: rgba(51, 51, 51, 0.8);
  color: #fff;
  padding: 5px;
  font-size: 12px;
}
.liste_themes {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0;
}
.liste_themes a {
  padding: 7px 5px;
  color: #fff;
  width: 120px;
  margin: 0 7px 7px 0;
  display: inline-block;
  text-align: center;
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear;
}
.liste_themes a:hover {
  text-decoration: none;
}
.liste_themes a.bleu {
  background: #004B99;
}
.liste_themes a.orange {
  background: #10772C;
}
.liste_themes a.vert {
  background: #10772C;
}
.liste_themes a.gris {
  background: #9a9a9a;
}
.liste_themes a.rouge {
  background: #b22600;
}
.liste_themes a.jaune {
  background: #d5da14;
}
.liste_themes a.bleu:hover {
  background: #001016;
}
.liste_themes a.orange:hover {
  background: #8b4a0a;
}
.liste_themes a.vert:hover {
  background: #45652f;
}
.liste_themes a.gris:hover {
  background: #676767;
}
.liste_themes a.rouge:hover {
  background: #4c1000;
}
.liste_themes a.jaune:hover {
  background: #7a7d0b;
}
.themes {
  position: relative;
  padding: 0 0 25px;
}
#owl-demo {
  padding: 0 0 10px;
}
#owl-demo .item {
  background: #3fbf79;
  margin: 0 15px 0 0;
  color: #fff;
  text-align: center;
  height: 150px;
  overflow: hidden;
}
#owl-demo .item img {
  width: 100%;
  height: 100%;
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
#owl-demo .item img:hover {
  -webkit-filter: brightness(70%);
  filter: brightness(70%);
}
.breadcrumb ul {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.breadcrumb ul > li {
  display: inline-block;
}
.breadcrumb ul > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/ ";
}
.flex-direction-nav,
.lush-nav {
  *height: 0;
}
.flex-direction-nav a,
.lush-nav a.lush-prev,
.lush-nav a.lush-next {
  width: 45px;
  height: 45px;
  margin: -23px 0 0;
  display: block;
  background: url(../images/images-bg_direction_nav.png) no-repeat 0 0;
  position: absolute;
  top: 40%;
  z-index: 10;
  cursor: pointer;
  text-indent: -9999px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  border: none;
}
.flex-direction-nav .flex-next,
.lush-nav .lush-next {
  background-position: 100% 0 !important;
  right: 0px;
}
.flex-direction-nav .flex-prev,
.lush-nav .lush-prev {
  left: 0px;
}
.flexslider:hover .flex-next,
.lush-slider:hover .lush-nav .lush-next {
  opacity: 0.8;
  right: 5px;
}
.flexslider:hover .flex-prev,
.lush-slider:hover .lush-nav .lush-prev {
  opacity: 0.8;
  left: 5px;
}
.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover,
.lush-slider:hover .lush-next,
.lush-slider:hover .lush-prev {
  opacity: 1;
  border: none;
}
.flex-direction-nav .flex-disabled,
.lush-direction-nav .lush-disabled {
  opacity: 0.3 !important;
  filter: alpha(opacity=30);
  cursor: default;
}
#partenaires .lush-nav a {
  background-color: rgba(54, 135, 216, 0.7);
}
.flex-direction-nav .flex-prev {
  left: 5px;
}
.flex-direction-nav .flex-next {
  right: 5px;
}
.slider:hover .flex-direction-nav a {
  opacity: 1;
}
.date-miseajour {
  margin: 20px 0;
  color: #a4b6c7;
}
.nav-tabs {
  border-bottom: 5px solid #004B99;
}
.nav-tabs > li > a {
  padding: 14px 15px 6px;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs > li > a:hover {
  color: #fff;
  background-color: #004B99;
  border: 1px solid #004B99;
  border-bottom: 5px solid #004B99;
}
@media (min-width: 992px) {
  .master-container .content {
    min-height: 700px;
  }
}
@media only screen and (max-width: 1200px) {
  .recherche {
    top: -44px;
    right: 0;
  }
}
@media only screen and (max-width: 992px) {
  .rep {
    margin: 1px 6px 1px 0;
  }
  .recherche {
    top: -34px;
  }
  .fb span {
    display: none;
  }
  .sidebar {
    max-width: 270px;
    margin: 0 auto;
    float: none;
    clear: both;
  }
}
@media only screen and (max-width: 886px) {
  .rep {
    display: none;
  }
  .recherche {
    top: -81px;
    right: 15px;
  }
  .fb {
    top: -36px;
    right: 15px;
  }
  .langue {
    top: 9px;
    right: 10px;
  }
  .navbar .container,
  #main-nav {
    min-height: 50px;
    margin: 0;
  }
  
  .flexslider h2 {
    font-size: 130% !important;
    line-height: 120% !important;
    padding: 7px !important;
  }
  .flexslider h3 {
    display: none !important;
  }
  #actualites,
  #acces-rapide,
  .col-content,
  .pub {
    margin-bottom: 15px;
  }
  .row.margin-bottom {
    margin-bottom: 0 !important;
  }
  .video img {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .recherche {
    top: -95px;
  }
  .navbar-form {
    float: left !important;
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .fb {
    top: -45px;
  }
}
@media only screen and (max-width: 636px) {
  .recherche,
  .fb {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .navbar-inner {
    padding: 0 5px;
  }
  .logo {
    margin: 7px 0 9px;
  }
  .pub {
    display: none;
  }
  .head_title {
    margin-top: 15px;
  }
}
.form_name {
  display: none;
}
.badge {
  background-color: #10772C;
  font-size: 80%;
  font-weight: normal;
  margin-left: 2px;
  position: relative;
  top: -8px;
}
#captcha_img {
  float: left;
}
#captcha_refresh {
  float: left;
  padding: 4px;
  cursor: pointer;
  margin-right: 2px;
  margin-left: 2px;
}
#captcha_refresh img:hover {
  background: #dae6f8;
}
#captcha_input {
  width: 60px !important;
}
.imageZ.cboxElement img {
  width: auto;
  height: 200px;
  margin-bottom: 3px;
  margin-right: 3px;
}
.navbar {
  margin: 0;
  min-height: auto;
  border: 0;
}

#main-nav {
  position: relative;
}

#main-nav {
  background: #004B99;
  width: 100vw;
}
.fixed {
  width: 100%;
  top: 0;
  background: #004B99;
  z-index: 99999;
  box-shadow: 1px 1px 5px #fff;
  -webkit-box-shadow: 1px 1px 5px #fff;
}
.mainmenu * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.mainmenu {
  margin: 0px 0 20px 0;
}
.mainmenu {
  width: 100%;
  padding: 0 10px;
  margin: 0;
  position: relative;
  float: left;
  list-style: none;
  z-index: 50;
}
#mainmenu {
  margin: 2px 0;
}
#mainmenu-1.mainmenu {
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 99999;
}
.mainmenu li {
  display: inline-block;
  float: left;
}
.mainmenu a {
  outline: 0;
  z-index: 999;
}
.mainmenu > li > a {
  padding: 13px 15px;
  line-height: 14px;
  color: #fafafa;
  text-decoration: none;
  display: inline-block;
  position: relative;
}
#mainmenu-1.mainmenu > li > a {
  color: #222;
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear;
  padding: 16px 12px 14px;
  margin-right: 1px;
}
.mainmenu li a .caret {
  margin-left: 5px;
}
.mainmenu ul.dropdown {
  list-style: none;
  margin: 0;
  padding: 5px 0;
  position: absolute;
  z-index: 99;
  width: 265px;
}
#mainmenu-1.mainmenu ul.dropdown {
  z-index: 9999;
}
.mainmenu ul.dropdown li ul.dropdown,
.mainmenu ul.dropdown li ul.dropdown li ul.dropdown {
  left: 100%;
  top: inherit;
}
.mainmenu ul.dropdown li {
  clear: both;
  width: 100%;
}
.mainmenu ul.dropdown li a {
  width: 100%;
  padding: 7px 15px;
  display: inline-block;
  float: left;
  clear: both;
  text-decoration: none;
  color: #222;
}
.mainmenu ul.dropdown li:hover > a {
  color: #004B99;
}
.mainmenu ul.dropdown li ul.dropdown.left {
  left: auto;
  right: 100%;
}
.mainmenu > li > .megamenu {
  position: absolute;
  color: #fff;
  z-index: 9999;
  padding: 10px 15px;
}
.mainmenu > li > .megamenu.one-col {
  padding: 0px;
}
.mainmenu li > .megamenu ul {
  margin: 0;
  float: left;
  padding: 0;
  position: relative;
}
.mainmenu li > .megamenu ul ul {
  padding: 0 0 0 10px;
  border-left: 1px dotted #777;
  margin-left: 1px;
}
.mainmenu li > .megamenu li.head > ul > li {
  margin-left: 0;
}
.mainmenu li > .megamenu ul li {
  width: 100%;
  padding: 4px 0;
}
.mainmenu li > .megamenu ul li.head {
  padding: 0;
}
.mainmenu li > .megamenu h5,
.mainmenu li > .megamenu h5 a {
  width: 100%;
  margin: 0;
  padding: 7px 0;
  color: #222;
}
.mainmenu li > .megamenu h5 {
  padding: 0;
}
.mainmenu li > .megamenu h5.parents {
  padding: 7px 0;
}
.mainmenu li > .megamenu a {
  color: #222;
  text-decoration: none;
  display: inline-block;
  width: 100%;
}
.mainmenu li > .megamenu a:hover {
  color: #10772C;
}
.mainmenu .megamenu img {
  width: 100%;
}
.mainmenu > li.fix-sub > .dropdown,
.mainmenu > li.fix-sub > .megamenu {
  right: 0;
}
.menu-button {
  display: none;
}
@media (max-width: 886px) {
  #mainmenu-1.mainmenu {
    margin-top: 10px;
  }
  #mainmenu.mainmenu,
  #mainmenu-1.mainmenu {
    padding: 0 0 0 25px;
  }
  #mainmenu.mainmenu {
    margin-bottom: 10px;
  }
  .mainmenu > li > a,
  .mainmenu li > .megamenu a,
  .mainmenu li > .megamenu h5.parents,
  .mainmenu li > .megamenu h5 a,
  .mainmenu ul.dropdown li a {
    color: #fff;
    padding: 7px 0 0;
    line-height: 24px;
  }
  #mainmenu-1.mainmenu > li > a {
    color: #fff;
    font-size: 16px;
  }
  .mainmenu li > .megamenu div,
  .mainmenu li > .megamenu ul {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .mainmenu > li {
    display: block;
    width: 100%;
  }
  .mainmenu > li > a,
  #mainmenu-1.mainmenu > li > a {
    padding: 7px 0;
  }
  .mainmenu a {
    width: 100%;
    display: inline-block;
    padding-top: 7px;
  }
  .mainmenu li > .megamenu ul {
    margin-left: 0;
    padding: 0;
  }
  .mainmenu li > .megamenu ul li.title {
    margin-bottom: 0;
  }
  .mainmenu li > .megamenu ul li.title h5 {
    margin-bottom: 0;
    border-bottom: 0;
    padding: 0;
  }
  .mainmenu li > .megamenu ul li {
    padding: 0;
  }
  .mainmenu > li > .megamenu,
  .mainmenu ul.dropdown,
  .mainmenu ul.dropdown li ul.dropdown {
    width: 100% !important;
    position: static !important;
  }
  .mainmenu ul.dropdown,
  .mainmenu ul.dropdown li ul.dropdown {
    left: 0;
    border: none;
  }
  .mainmenu > li > .megamenu {
    border-top: none;
    color: #fff;
  }
  .mainmenu ul.dropdown li {
    background: none;
    border: none;
    margin-left: 15px;
  }
  .mainmenu > li > .megamenu .row,
  .mainmenu > li > .megamenu .row:first-child [class*="col"]:first-child {
    margin-top: 0;
  }
  .mainmenu > li.active > a,
  .mainmenu > li > a:hover,
  .mainmenu li > .megamenu a:hover,
  .mainmenu ul.dropdown li a:hover {
    color: #10772C;
  }
  .menu-button {
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 50px;
    height: 40px;
    top: 5px;
    left: 15px;
    font-size: 0;
    text-indent: -9999px;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background-color: #999;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }
  .menu-button:focus {
    outline: 0;
  }
  .menu-button span {
    display: block;
    position: absolute;
    top: 18px;
    left: 15px;
    right: 15px;
    height: 2px;
    background: #fff;
  }
  .menu-button span {
    -webkit-transition: background 0s 0.3s;
    transition: background 0s 0.3s;
  }
  .menu-button.is-active {
    background-color: #91bfd3;
  }
  .menu-button.is-active span {
    background: 0 0;
  }
  .menu-button span:after,
  .menu-button span:before {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    content: "";
  }
  .menu-button span:before {
    top: -8px;
  }
  .menu-button span:after,
  .menu-button span:before {
    -webkit-transition-duration: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    -webkit-transition-delay: 0.3s, 0s;
    transition-delay: 0.3s, 0s;
  }
  .menu-button span:before {
    -webkit-transition-property: top, -webkit-transform;
    transition-property: top, transform;
  }
  .menu-button.is-active span:before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .menu-button.is-active span:after,
  .menu-button.is-active span:before {
    -webkit-transition-delay: 0s, 0.3s;
    transition-delay: 0s, 0.3s;
  }
  .menu-button span:after {
    bottom: -8px;
  }
  .menu-button span:after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
  }
  .menu-button.is-active span:after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .mainmenu li > .megamenu ul li.title {
    border: none;
  }
  .mainmenu {
    display: none;
  }
  .mainmenu li > .megamenu,
  .mainmenu li > .dropdown {
    display: none;
    padding: 0;
  }
  .navnav{
    display: none !important;
  }
  .mobileNavbar{
    display: block !important;
  }
  .logoFlex{
    display: none;
  }
  .Ministere h1{
    display: none;
  }
  .FnarcLogo{
    width: 75vw;
  }
  h3{
    width:100vw !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .Ministere{
    font-size: 1rem !important;
  }
}
.mobileNavbar{
  display: none;

}
.hamburger > span {
  width: 4rem;
  height: 5px;
  background-color: #1faa44;
  position: absolute;
  border-radius: 5px;
}
.hamburger{
  position: absolute;
  top: 3.33vh;
  right: 7rem;
}
.hamburger > span:nth-of-type(1) {
  top: 0;
}
.hamburger > span:nth-of-type(2) {
  top: 10px;
}
.hamburger > span:nth-of-type(3) {
  top: 20px;
}
@media only screen and (min-width: 886px) {
  #mainmenu-1.mainmenu {
    background: #ecf0f2;
  }
  .mainmenu > li > .megamenu,
  .mainmenu ul.dropdown,
  .mainmenu ul.dropdown li ul.dropdown,
  .mainmenu ul.dropdown li ul.dropdown li ul.dropdown {
    background: #fff;
  }
  .mainmenu > li > .megamenu,
  .mainmenu ul.dropdown {
    box-shadow: 0px 2px 3px rgba(153, 153, 153, 0.8);
    -webkitbox-shadow: 0px 2px 3px rgba(153, 153, 153, 0.8);
  }
  .mainmenu > li > .megamenu {
    color: #222;
  }
  .mainmenu > li.active > a,
  .mainmenu > li:hover > a {
    background: #fff;
    color: #004B99;
  }
  #mainmenu-1.mainmenu > li.active > a:before,
  #mainmenu-1.mainmenu > li > a:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 3px;
    width: 100%;
    background: #004B99;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }
  #mainmenu-1.mainmenu > li.active > a:before,
  #mainmenu-1.mainmenu > li > a:before {
    width: 0;
  }
  #mainmenu-1.mainmenu > li.active > a:before,
  #mainmenu-1.mainmenu > li > a:before {
    background: #10772C;
    z-index: -1;
  }
  #mainmenu-1.mainmenu > li.active > a:before,
  #mainmenu-1.mainmenu > li:hover > a:before {
    width: 100%;
  }
  .mainmenu ul li a:hover,
  .mainmenu ul li a:focus {
    color: #10772C;
  }
  .mainmenu .megamenu .row {
    margin-bottom: 0;
  }
  .mainmenu > li {
    overflow: hidden;
  }
  .mainmenu > li:hover {
    overflow: visible;
  }
}
@media (min-width: 886px) and (max-width: 1050px) {
  .fix-sub-tablet > .megamenu {
    right: 0;
  }
}
@media (max-width: 1200px) {
  #mainmenu-1.mainmenu {
    padding-right: 90px;
  }
}
@media (max-width: 1017px) {
  .row .row {
    margin: 0;
  }
}
@media (max-width: 480px) {
  .menu-button {
    left: 10px;
  }
  #mainmenu.mainmenu,
  #mainmenu-1.mainmenu {
    padding: 0 0 0 10px;
    margin-top: 10px;
  }
}
@media (min-width: 886px) {
  @keyframes hoverExpand {
    0% {
      opacity: 0;
      transform: rotateY(90deg);
    }
    100% {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes hoverExpand {
    0% {
      opacity: 0;
      -webkit-transform: rotateY(90deg);
    }
    100% {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
  }
  .mainmenu.hoverAnimation li > .megamenu,
  .mainmenu.hoverAnimation li ul.dropdown {
    display: none;
  }
  .mainmenu.hoverAnimation li:hover > .megamenu,
  .mainmenu.hoverAnimation li.haschild:hover > ul.dropdown {
    display: block;
    animation: hoverExpand 0.3s linear 0s;
    -webkit-animation: hoverExpand 0.3s linear 0s;
  }
}
li.haschild{
  margin: auto !important;
  font-weight: bold;
}
body {
  font-family: 'Tajawal', sans-serif;
  background: #ecf0f2;
  color: #222;
}
a {
  color: #004B99;
}
img,
object,
embed,
video {
  max-width: 100%;
}
.ie6 img {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
p,
table {
  margin: 0 0 15px 0;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5 {
  color: #004B99;
}
h2,
h4 {
  color: #10772C;
}
h1 {
  font-size: 200%;
}
h2 {
  font-size: 160%;
}
h3 {
  font-size: 130%;
}
h4 {
  font-size: 110%;
}
hr {
  border-top: 1px solid #ced8dd;
}
input,
textarea {
  padding: 2px;
}
input[type="number"] {
  direction: ltr;
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.rouge {
  color: #b22600;
}
.brd-rouge {
  border: 1px solid #b22600;
}
.brdvert {
  border: 1px solid #10772C;
}
.flash-danger,
.flash-success,
.flash-info {
  background-color: #f7e9e6;
  border: 2px solid #b22600;
  border-left: 0;
  border-right: 0;
  color: #b22600;
  width: 100%;
  padding: 15px;
}
.flash-success {
  background-color: #f1f7ee;
  border-color: #10772C;
  color: #10772C;
}
.flash-info {
  background-color: #eff3f3;
  border-color: #61838a;
  color: #61838a;
}
.at-share-btn-elements {
  padding-top: 10px;
}
.at-share-btn-elements a {
  -webkit-border-radius: 20px !important;
  border-radius: 20px !important;
}
.blink-me {
  animation: blinker 1s ease-in-out infinite;
}
@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}
.tableau_formulaire tr {
  border-bottom: #fff solid 5px;
}
label {
  font-weight: 400;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
  }
}
.style_prevu_kit {
  display: inline-block;
  border: 0;
  width: 50%;
  height: 112px;
  position: relative;
  transition: all 200ms ease-in;
  transform: scale(1);
}
.style_prevu_kit_0 {
  transform-origin: top left;
}
.style_prevu_kit_1 {
  transform-origin: top right;
}
.style_prevu_kit_2 {
  transform-origin: left;
}
.style_prevu_kit_3 {
  transform-origin: right;
}
.style_prevu_kit_4 {
  transform-origin: bottom left;
}
.style_prevu_kit_5 {
  transform-origin: bottom right;
}
.style_prevu_kit:hover {
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
  z-index: 2;
  transform: scale(2);
}
@media (min-width: 768px) {
  .accueil-bloc-1 {
    min-height: 370px;
  }
  .accueil-bloc-2 {
    min-height: 240px;
  }
}
/*# sourceMappingURL=styles-15.css.map */
li > a {
    font-size: 1.1rem;
}
.animatedBg{
  animation: animate 2s infinite ease-in-out;
}
@keyframes animate {
  0%{
    background-color: #D0364F;
  }
  25%{
    background-color: #E65A71;
  }
  50%{
    background-color: #9BEAFB;
  }
  75%{
    background-color: #FDE774;
  }
  100%{
    background-color: white;
  }
}
.navbar{
  position: -webkit-sticky;
  position: sticky !important;
  top: 0 !important;
}
.Ministere{
  min-height: 95px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 3rem;
  animation: animate 3s infinite ease;
}
.Ministere h1{
  font-weight: bold;
}
.FnarcLogo {
  width: 9rem;
  height: 9rem;
}
.TunisieLogo{
  width: 8rem;
  height: 8rem;
  border: 5px solid #fff;
}

.logoFlex{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-size: 1rem !important;
}
@keyframes animate {
9%{
    background-image: linear-gradient(to right,#fff , #eef1ff, #fff, #eef1ff, #fff );
  }
  50%{
    background-image: linear-gradient(to right,#fff , #eef1ff, #fff, #eef1ff, #fff );
  }
  75%{
    background-image: linear-gradient(to right,#eef1ff , white, #eef1ff, white, #eef1ff );
  }
  100%{
    background-image: linear-gradient(to right,#eef1ff , white, #eef1ff, white, #eef1ff );
  }
}
.navbar {
  z-index: 99999999999999 !important;
}
.offcanvas.offcanvas-end.show{
  width: 90vw;
  z-index: 9999999999999999 !important;
}
.offcanvas-body{
  width: 100vw;
  height: 100vh;
  padding: 10rem;
}
